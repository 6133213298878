import React from "react";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Table/styles.scss"

export default function Table (props) {
    const getExtraClassName = () => {
        const {tableOptions} = props;
        let extraClassName = "";
        if (tableOptions && tableOptions.onRowClick) {
            extraClassName += "tr-hover-pointer";
        }
        return extraClassName;
    };

    const {
        noRecordsMsg,
        data,
        tableOptions,
        columns,
        onRowSelect,
        selectedRow,
        search,
        pagination,
        multiColumnSearch,
        sortIndicator,
        exportCSV,
        tdStyle,
        selectRow = {},
        trClassName,
    } = props;
    const extraClassName = getExtraClassName();

    return (
        <div>
            <BootstrapTable
                data={data}
                striped
                hover
                pagination={pagination}
                multiColumnSearch={multiColumnSearch}
                trClassName={(row, rowId) => {
                    let finalClassName = "";
                    if (extraClassName) {
                        finalClassName += " " + extraClassName;
                    }
                    if (trClassName) {
                        finalClassName += " " + trClassName(row, rowId);
                    }
                    return finalClassName;
                }}
                search={search}
                exportCSV={exportCSV}
                selectRow={
                    onRowSelect ?
                        {
                            mode: "radio",
                            bgColor: "lightgray",
                            hideSelectColumn: true,
                            clickToSelect: true,
                            onSelect: onRowSelect,
                            selected: [selectedRow]
                        } :
                        selectRow
                }
                options={{
                    ...tableOptions,
                    noDataText: noRecordsMsg || "No Results",
                    sortIndicator: sortIndicator,
                    paginationShowsTotal: true,
                }}
            >
                {
                    columns.map((col, index) => (
                        <TableHeaderColumn
                            key={index}
                            dataField={col.key}
                            dataSort
                            tdStyle={tdStyle}
                            dataFormat={col.value || (val => val)}
                            width={col.width || "100"}
                            {...col.extra}
                        >
                            {col.title}
                        </TableHeaderColumn>
                    ))
                }
            </BootstrapTable>
        </div>
    );
}

