import { combineReducers } from 'redux';
import {IState as ILogin} from "./login";
import {IState as IOrganization} from "./organization"
import {IState as ITraining} from "./training";
import login from "./login";
import organization from "./organization";
import training from "./training";
export interface IReduxState {
    login: ILogin,
    organizations: IOrganization,
    training: ITraining,
}
export default combineReducers<IReduxState>({
    login: login,
    organizations: organization,
    training: training,
});