import React from "react";
import {FieldKeyNames, IPage} from "../../../models";
import {Select} from "../../../utils/inputFunctions";
import {disableDelete, disableInsert, pageTypeList} from "../utils";

interface IProps {
    currentPageIndex: number;
    pages: IPage[];
    handleInputChange: (fieldName: string, value: number | string) => void;
    handleDeleteCurrentPage: (pageId: number) => void;
    handleInsertPage: () => void;
    handleSaveTraining: () => void;
}
export default function ActivityButtons(props: IProps) {
    return (
        <div className={"w-100 d-flex"}>
            <div className={"w-25 mr-2"}>
                <Select value={props.currentPageIndex}
                        label={"Page No"}
                        options={props.pages.map((i, index) => {
                            return {
                                name: i.id-1,
                                value: index,
                            }
                        })}
                        fieldName={FieldKeyNames.PAGE_INDEX}
                        onChange={props.handleInputChange}
                />
            </div>
            <div className={"w-25 mr-2"}>
                <Select value={props.pages[props.currentPageIndex].type}
                        label={"Page Type"}
                        options={pageTypeList}
                        fieldName={FieldKeyNames.PAGE_TYPE}
                        onChange={props.handleInputChange}
                        disabled={true}
                />
            </div>
            <div className={"w-25 mr-2 mt-3 pt-3"}>
                <button className={`delete-page-btn ${disableDelete(props.pages[props.currentPageIndex].type) ? "not-allowed" : "cursor-pointer"}`}
                        onClick={() => props.handleDeleteCurrentPage(props.currentPageIndex + 1)}
                        disabled={disableDelete(props.pages[props.currentPageIndex].type)}
                >
                    <i className="mr-2 fa fa-trash" aria-hidden="true"/>
                    Delete Current Page
                </button>
            </div>
            <div className={"w-25 mr-2 mt-3 pt-3"}>
                <button className={`add-page-btn ${disableInsert(props.pages[props.currentPageIndex].type) ? "not-allowed" : "cursor-pointer"}`}
                        disabled={disableInsert(props.pages[props.currentPageIndex].type)}
                        onClick={() => props.handleInsertPage()}
                >
                    <i className="mr-2 fa fa-plus-circle" aria-hidden="true"/>
                    Insert New Page (After)
                </button>
            </div>
            <div className={"w-25 mr-2 mt-3 pt-3"}>
                <button className="add-page-btn"
                        onClick={() => props.handleSaveTraining()}
                >
                    <i className="mr-2 fa fa-plus-circle" aria-hidden="true"/>
                    Save All Changes
                </button>
            </div>
        </div>
    )
}