import React, {useState} from "react";
import dispatch from "../../middleware";
import {deleteOrg, getAllOrganizations, resetOrganizationResponse} from "../../actions/organization";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../common/Modal";
import {Redirect} from "react-router-dom";
import {IState as IOrganizationState} from "../../reducers/organization";
import {IReduxState} from "../../reducers";


export default function DeleteOrganization(props) {
    const storeDispatch = useDispatch();
    const [deleteOrgModalActive, setDeleteOrgModalActive] = useState<boolean>(false);
    const [redirectToOrganization, setRedirectToOrganization] = useState<boolean>(false);

    const deleteOrgOnClick = () => {
        const deleteOrgIdPayload = {
            orgId: props.selectedOrgId
        };
        dispatch(storeDispatch, deleteOrg(deleteOrgIdPayload)).then((r) => {
            setRedirectToOrganization(true);
        });
        setDeleteOrgModalActive(false);
    };
    if (redirectToOrganization) {
        return <Redirect to="/organizations"/>;
    }
    return (
        <div className="form-group" style={{textAlign:"right", padding: 20}}>
            <Modal
                title={"Delete Organization"}
                active={deleteOrgModalActive}
                onClose={() => setDeleteOrgModalActive(false)}
                onSubmit={deleteOrgOnClick}
                submitTitle={"Yes"}
                cancellable
            ><span className="float-left">
                Are you sure to delete this organization?
                </span>
            </Modal>
            <button className={"btn btn-danger"} onClick={() => setDeleteOrgModalActive(true)} type="button">
                Delete Organization
            </button>
        </div>
    )
}