import React from "react";
import {Switch, Route} from "react-router-dom";
import Organizations from "./organizations";
import SelectedOrganization from "./selectedOrganization";
import "./styles.css";

export default function OrganizationSection(props: any) {
    const orgId = props.location && props.location.state && props.location.state.orgId;
    return (
        <div className="text-center mt-4 pt-4">
            <Switch>
                <Route exact path={`${props.match.url}`} component={Organizations}/>
                <Route exact path={`${props.match.url}/${orgId}`} component={SelectedOrganization}/>
            </Switch>
        </div>
    )
}