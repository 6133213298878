import React from 'react';
import {Link} from "react-router-dom";
import "./styles.css";

export default function Dashboard() {
    return (
        <div className="text-center mt-4 pt-4">
            <Link to={"/trainings"} style={{textDecoration: "none"}}>
                <div className="dashboard-btn">
                    Trainings
                </div>
            </Link>
            <Link to={"/organizations"} style={{textDecoration: "none"}}>
                <div className="dashboard-btn">
                    Organizations
                </div>
            </Link>
        </div>
    );
}
