import {IResponseMessage} from "../../models";

export function DownloadCsv(csvData, fileName) {
    const b64 = window.btoa(csvData.join("\n"));
    const downloadData = `data:text/csv;base64, ${b64}`;
    const element = document.createElement("a");
    element.setAttribute("href", downloadData);
    element.download = `${fileName}.csv`;

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
}

export function getOrganizationResponse(status: string, msg: string): IResponseMessage {
    return {
        status,
        msg,
    };
}