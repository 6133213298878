import React from 'react';
import Header from "../constants/header";
import Dashboard from "../constants/dashboard";
import Login from "../constants/login";
import {useSelector} from "react-redux";
import {IReduxState} from "../reducers";
import {
    HashRouter as Router,
    Route, Switch
} from "react-router-dom";
import {history} from "../store";
import "./style.css";
import TrainingSection from "../components/training";
import OrganizationSection from "../components/organization";

function App() {
    let isLoggedIn: boolean = useSelector((state: IReduxState) => state.login.isLoggedIn);

    function getComponent(loggedInStatus: boolean) {
        if (loggedInStatus) {
            return (
                <Router>
                    <Switch>
                        <Route path="/organizations" component={OrganizationSection}/>
                        <Route path="/trainings" component={TrainingSection}/>
                        <Route exact patch="/" component={Dashboard}/>
                    </Switch>
                </Router>
            );
        } else {
            history.push("/");
            return <Login/>
        }
  }
  return (
    <div>
      <Header/>
      <div className="middle-section">
          {getComponent(isLoggedIn)}
      </div>
    </div>
  );
}
export default App;
