import React from "react";
import Logo from "../images/logo.png";
import "./styles.css";

export default function Header() {
    return (
        <div className="header">
            <a href="/#"> <img src={Logo} alt="UCS" style={{width: "auto", height: "7vh", maxHeight: "70px"}}/></a>
        </div>
    )
}