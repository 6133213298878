import React, {useState} from "react";
import "../styles.css";
import {IState as ITrainingState} from "../../../reducers/training";
import {useDispatch, useSelector} from "react-redux";
import {IReduxState} from "../../../reducers";
import {FieldKeyNames, IQuestions, IUpdateSelectedTrainingDetails, PageTypes} from "../../../models";
import {clickableRoutes, getQuizPageIds, insertablePageTypeList, updateSelectedTrainingDetails} from "../utils";
import {
    deleteCurrentPage,
    insertNewPage,
    resetSaveTrainingResponse,
    saveTraining,
    updateTrainingDetails
} from "../../../actions/training";
import dispatch from "../../../middleware";
import ActivityButtons from "./activityButtons";
import Markdown from "./markdown";
import Modal from "../../common/Modal";
import Quiz from "./quiz";
import {Select} from "../../../utils/inputFunctions";
import {fullScreenLoader} from "../../../utils/htmlFunctions";
import {Redirect} from "react-router-dom";
import QuizResult from "./quizResult";

export default function TrainingPage(props) {
    const routes = [{name: "Training List", path: "/trainings"}, {name: "Training Details", path: "/trainings/trainingDetails"}, {name: "Training Pages", path: ""}];
    const storeDispatch = useDispatch();
    let training: ITrainingState = useSelector((state: IReduxState) => state.training);
    let [currentPageIndex, updateCurrentPageIndex] = useState<number>(0);
    let [typeOfNewPage, updateTypeOfNewPage] = useState<PageTypes | string>(PageTypes.SECTION_START);
    let [modalActive, updateModalStatus] = useState<boolean>(false);
    if (Object.keys(training.editableTraining).length === 0) {
        return <Redirect to="/"/>;
    }
    function handleDeleteCurrentPage(pageId: number) {
        dispatch(storeDispatch, deleteCurrentPage(pageId));
    }
    function handleInsertPageModal() {
        updateModalStatus(true);
    }
    function handleInsertPage() {
        updateModalStatus(false);
        updateCurrentPageIndex(currentPageIndex + 1);
        dispatch(storeDispatch, insertNewPage(currentPageIndex, typeOfNewPage));
    }
    function handleInputChange(fieldName: string, value: number | string | IQuestions[]) {
        if (fieldName === FieldKeyNames.PAGE_INDEX) {
            updateCurrentPageIndex(Number(value));
        } else {
            const trainingDetails: IUpdateSelectedTrainingDetails =
            updateSelectedTrainingDetails(training.editableTraining, fieldName, value, currentPageIndex);
            dispatch(storeDispatch, updateTrainingDetails(trainingDetails.updatedTrainingObj, trainingDetails.reArrangeReferences));
        }
    }
    function handleSaveTraining() {
        dispatch(storeDispatch, saveTraining(training.editableTraining, training.trainingId)).then((r) => {
            setTimeout(() => {
                dispatch(storeDispatch, resetSaveTrainingResponse())
            }, 3000)
        });
    }
    return (
        <div className="text-center">
            {training.loading && fullScreenLoader()}
            {clickableRoutes(routes)}
            <div className={"container-fluid d-flex justify-content-center"}>
                <div className="w-100 box overflowX-scroll">
                    <div className={"training-name"}>{training.editableTraining.name}</div>
                    <ActivityButtons pages={training.editableTraining.pages} currentPageIndex={currentPageIndex}
                             handleDeleteCurrentPage={handleDeleteCurrentPage} handleInputChange={handleInputChange}
                             handleInsertPage={handleInsertPageModal} handleSaveTraining={handleSaveTraining}

                    />
                    {training.saveTrainingResponse.status.trim() !== "" &&
                    <div className={training.saveTrainingResponse.status === "SUCCESS" ? "text-success" : "text-danger"}>
                        {training.saveTrainingResponse.msg}
                    </div>}
                    {
                        training.editableTraining.pages[currentPageIndex].type === PageTypes.QUIZ &&
                        <Quiz passPercent={training.editableTraining.pages[currentPageIndex].passPercent}
                              questions={training.editableTraining.pages[currentPageIndex].questions}
                              handleInputChange={handleInputChange}
                        />
                    }
                    {
                        training.editableTraining.pages[currentPageIndex].type === PageTypes.QUIZ_RESULTS &&
                        <QuizResult
                            quizPageId={training.editableTraining.pages[currentPageIndex].quizPageId}
                            quizPageIdList={getQuizPageIds(training.editableTraining.pages, currentPageIndex)}
                            handleInputChange={handleInputChange} pageReferences={training.pageReferences}
                        />
                    }
                    {
                        (training.editableTraining.pages[currentPageIndex].type !== PageTypes.QUIZ_RESULTS
                        && training.editableTraining.pages[currentPageIndex].type !== PageTypes.QUIZ
                        ) &&
                        <Markdown pages={training.editableTraining.pages} currentPageIndex={currentPageIndex}
                                  handleInputChange={handleInputChange}/>
                    }
                </div>
            </div>
            <Modal
                title={"Please Mention Page Type"}
                submitTitle={"Submit"}
                active={modalActive}
                onClose={() => updateModalStatus(false)}
                onSubmit={() => handleInsertPage()}
                cancellable
            >
                <Select value={typeOfNewPage}
                        label={"Page Type"}
                        options={insertablePageTypeList}
                        fieldName={FieldKeyNames.PAGE_TYPE}
                        onChange={(fieldName, value) => updateTypeOfNewPage(String(value))}
                />
            </Modal>
        </div>
    )
}