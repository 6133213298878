import React from "react";
import {FieldKeyNames, InputFieldTypes, IPage} from "../../../models";
import {TextField} from "../../../utils/inputFunctions";
import ReactMarkdown from "react-markdown";
import {unescapeString} from "../utils";

interface IProps {
    currentPageIndex: number;
    pages: IPage[] | any;
    handleInputChange: (fieldName: string, value: number | string) => void;
}
export default function Markdown(props: IProps) {
    return (
       <div>
           {props.pages[props.currentPageIndex].hasOwnProperty("videoUrl") &&
           <div className="w-50">
               <TextField value={props.pages[props.currentPageIndex].videoUrl} label={"Videl URL"}
                          fieldName={FieldKeyNames.VIDEO_URL} onChange={props.handleInputChange} type={InputFieldTypes.TEXT}/>
           </div>}
           {props.pages[props.currentPageIndex].hasOwnProperty("imageUrl") &&
           <div className="w-50">
               <TextField value={props.pages[props.currentPageIndex].imageUrl} label={"Image URL"}
                          fieldName={FieldKeyNames.IMG_URL} onChange={props.handleInputChange} type={InputFieldTypes.TEXT}/>
           </div>}
           { props.pages[props.currentPageIndex].hasOwnProperty("mdText") &&
           <div className={"w-100 d-flex"}>
               <div className="w-50 mr-2">
                           <textarea className={"md-text-box"}
                                     onChange={(e) => props.handleInputChange(FieldKeyNames.MD_TEXT, e.target.value)}
                                     value={unescapeString(props.pages[props.currentPageIndex].mdText)}
                           />
               </div>
               <div className="w-50 md-text-box ml-2">
                   <ReactMarkdown source={unescapeString(props.pages[props.currentPageIndex].mdText)} />
               </div>
           </div>
           }
       </div>
    )
}