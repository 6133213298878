import {Actions} from "../actions/login";
import {saveToken} from "../middleware";

export interface IState {
    loading: boolean;
    isLoggedIn: boolean;
    loginErrorMsg: string
}

const defaultState: IState = {
    isLoggedIn: false,
    loading: false,
    loginErrorMsg: "",
};

export default (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.REQUEST_LOGIN:
            return {
                ...state,
                loading: true,
                loginErrorMsg: "",
            };
        case `${Actions.REQUEST_LOGIN}_SUCCESS`:
            saveToken(action.payload);
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
            };
        case `${Actions.REQUEST_LOGIN}_FAILURE`:
            return {
                ...state,
                loading: false,
                isLoggedIn: false,
                loginErrorMsg: "Couldn't find a user! Please check your id and password.",
            };
        default:
            return state
    }
}