import React from "react";

export function fullScreenLoader() {
    return (
        <div style={{zIndex: 99, position: "absolute", width: "100%", height: "100%",
            background: "rgba(255,255,255,0.8)"}} className={"text-center"}>
            <div style={{position:"fixed", top: "40%"}} className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}