import {Actions} from "../actions/training";
import {IPage, ITraining, ITrainingMeta} from "../models";
import {
    getSaveTrainingResponse,
    getNewPage,
    getPageReferences,
    reArrangePageIds,
    shouldDeletePage
} from "../components/training/utils";
import {handleExportToCsvFeedbacks} from "../components/organization/selectedOrgUserDetailsCsv";

export interface IState {
    loading: boolean;
    trainingList: ITrainingMeta[];
    training: ITraining | object;
    editableTraining: ITraining | any;
    insertTrainingResponse: string;
    pageReferences: {[pageId: number]: number};
    saveTrainingResponse: {
        status: string;
        msg: string;
    },
    trainingId: string;
}

const defaultState: IState = {
    trainingList: [],
    loading: false,
    training: {},
    editableTraining: {},
    insertTrainingResponse: "",
    pageReferences: {},
    saveTrainingResponse: {
        status: "",
        msg: "",
    },
    trainingId: "",
};

export default (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.REQUEST_TRAININGS:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_TRAININGS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                trainingList: action.payload.trainings,
            };
        case `${Actions.REQUEST_TRAININGS}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_TRAINING_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_TRAINING_DETAILS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                training: JSON.parse(JSON.stringify(action.payload)),
                editableTraining: JSON.parse(JSON.stringify(action.payload)),
                pageReferences: getPageReferences(action.payload.pages),
            };
        case `${Actions.REQUEST_TRAINING_DETAILS}_FAILURE`:
            return {
                ...state,
                loading: false,
                trainingId: ""
            };
        case Actions.REQUEST_SAVE_TRAINING:
            return {
                ...state,
                loading: true,
                saveTrainingResponse: getSaveTrainingResponse("", ""),
            };
        case `${Actions.REQUEST_SAVE_TRAINING}_SUCCESS`:
            return {
                ...state,
                loading: false,
                training: JSON.parse(JSON.stringify(state.editableTraining)),
                saveTrainingResponse: getSaveTrainingResponse("SUCCESS", "Training updated successfully!"),

            };
        case `${Actions.REQUEST_SAVE_TRAINING}_FAILURE`:
            return {
                ...state,
                loading: false,
                saveTrainingResponse: getSaveTrainingResponse("FAILURE", "Ops! Couldn't update training!"),
            };
        case Actions.REQUEST_INSERT_TRAINING: {
            return {
                ...state,
                loading: true,
                insertTrainingResponse: "",
            }
        }
        case `${Actions.REQUEST_INSERT_TRAINING}_SUCCESS`: {
            return {
                ...state,
                loading: false,
                insertTrainingResponse: "SUCCESS",
            }
        }
        case `${Actions.REQUEST_INSERT_TRAINING}_FAILURE`: {
            return {
                ...state,
                loading: false,
                insertTrainingResponse: "FAILURE",
            }
        }
        case Actions.CLEAR_TRAINING_DETAILS:
            return {
                ...state,
                training: {},
                editableTraining: {},
                pageReferences: {},
                trainingId: "",
            }
        case Actions.UPDATE_TRAINING_DETAILS:
            return {
                ...state,
                editableTraining: action.payload.training,
                pageReferences: action.payload.reArrangeReferences ? getPageReferences(action.payload.training.pages) : state.pageReferences,
            }
        case Actions.REVERT_TRAINING_DETAILS_CHANGE:
            return {
                ...state,
                editableTraining: JSON.parse(JSON.stringify(state.training)),
            }
        case Actions.CLEAR_INSERT_TRAINING_RESPONSE:
            return {
                ...state,
                insertTrainingResponse: "",
            }
        case Actions.DELETE_CURRENT_PAGE: {
            const deletePageId = action.payload.pageId;
            let updatedPageList: IPage[] = state.editableTraining.pages.filter((page) => {
                return !shouldDeletePage(deletePageId, page.id, state.pageReferences)
            });
            updatedPageList = reArrangePageIds(updatedPageList, state.pageReferences);
            return {
                ...state,
                editableTraining: {...state.editableTraining, pages: updatedPageList},
                pageReferences: getPageReferences(updatedPageList),
            }
        }
        case Actions.INSERT_PAGE: {
            const page = getNewPage(action.payload.pageType);
            state.editableTraining.pages.splice(action.payload.currentPageIndex + 1, 0, page);
            let updatedPageList: IPage[] = state.editableTraining.pages;
            updatedPageList = reArrangePageIds(updatedPageList, state.pageReferences);

            return {
                ...state,
                editableTraining: {...state.editableTraining, pages: updatedPageList},
                pageReferences: getPageReferences(updatedPageList),
            }
        }
        case Actions.RESET_SAVE_TRAINING_RESPONSE: {
            return {
                ...state,
                saveTrainingResponse: getSaveTrainingResponse("", ""),
            }
        }
        case Actions.SET_TRAINING_ID: {
            return {
                ...state,
                trainingId: action.payload.trainingId,
            }
        }
        case Actions.REQUEST_GET_FEEDBACK:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_GET_FEEDBACK}_SUCCESS`:
            handleExportToCsvFeedbacks(action.payload.feedbacks);
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_GET_FEEDBACK}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}