import React from "react";
import {Switch, Route} from "react-router-dom"
import TrainingDashboard from "./trainingDashboard";
import TrainingDetails from "./editTraining/trainingDetails";
import "./styles.css";
import TrainingPage from "./editTraining/trainingPage";
// TODO: figure out why RouteComponentProps type doesn't work for me
export default function TrainingSection(props: any) {
    return (
        <div className="text-center mt-2">
            <Switch>
                <Route exact path={`${props.match.path}/trainingDetails`} component={TrainingDetails}/>
                <Route exact path={`${props.match.path}/trainingPage`} component={TrainingPage}/>
                <Route exact path={`${props.match.path}`} component={TrainingDashboard}/>
            </Switch>
        </div>
    )
}