import {
    FieldKeyNames,
    IPage,
    IQuestions,
    IResponseMessage,
    ITraining,
    IUpdateSelectedTrainingDetails,
    PageTypes,
    TrainingType
} from "../../models";
import {Link} from "react-router-dom";
import React from "react";
import {getDateStringFormSecond} from "../../utils/utilityFunctions";

export function updateSelectedTrainingDetails(trainingObj: ITraining | any, fieldName: string, value: string | number | IQuestions[], pageIndex?: number): IUpdateSelectedTrainingDetails {
    let reArrangeReferences = false;
    if (fieldName === FieldKeyNames.TRAINING_NAME) {
        trainingObj.name = String(value);
    } else if (fieldName === FieldKeyNames.TRAINING_LANGUAGE) {
        trainingObj.language = String(value);
    } else if (fieldName === FieldKeyNames.MD_TEXT && pageIndex !== undefined) {
        trainingObj.pages[pageIndex]["mdText"] = escape(String(value));
    } else if (fieldName === FieldKeyNames.VIDEO_URL && pageIndex !== undefined) {
        trainingObj.pages[pageIndex].videoUrl = String(value);
    } else if (fieldName === FieldKeyNames.IMG_URL && pageIndex !== undefined) {
        trainingObj.pages[pageIndex].imageUrl = String(value);
    } else if (fieldName === FieldKeyNames.PASS_PERCENT && pageIndex !== undefined && Number(value) >= 0) {
        trainingObj.pages[pageIndex].passPercent = Number(value)
    } else if (fieldName === FieldKeyNames.QUIZ && pageIndex !== undefined) {
        trainingObj.pages[pageIndex].questions = value;
    } else if (fieldName === FieldKeyNames.QUIZ_ID && pageIndex !== undefined) {
        trainingObj.pages[pageIndex].quizPageId = value !== "SELECT_QUIZ_PAGE_ID" ? Number(value) : null
        reArrangeReferences = true;
    }
    return {
        updatedTrainingObj: trainingObj,
        reArrangeReferences,
    };
}

export function disableDelete(pageType: PageTypes) {
    return (pageType === PageTypes.WELCOME || pageType === PageTypes.FINISH);
}
export function disableInsert(pageType: PageTypes) {
    return pageType === PageTypes.FINISH;
}
export function escape(str: string) {
    return str
        .replace(/[\"]/g, '\\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');
}
export function unescapeString(str: string) {
    return str
        .replace(/\\n/g, '\n')
        .replace(/\\\"/g, '\"')
        .replace(/\\f/g, '\f')
        .replace(/\\r/g, '\r')
        .replace(/\\t/g, '\t')
        .replace(/\//g, '\/')
}
export function validateNewTraining(training: ITraining) {
    let validation = {
        status: true,
        msg: "",
        type: "",
    };
    if (training.name.trim() === "") {
        validation.status = false;
        validation.msg = "Please enter a training name";
        validation.type = FieldKeyNames.NAME;
    } else if (training.language === "SELECT_LANGUAGE") {
        validation.status = false;
        validation.msg = "Please select a training language";
        validation.type = FieldKeyNames.LANGUAGE;
    }
    return validation;
}

export const defaultValidation = {
    status: false,
    msg: "",
    type: "",
};

export const defaultNewTraining = {
    name: "",
    type: TrainingType.AWARENESS,
    language: "ENGLISH",
    lastEdited: 0,
    editedBy: "",
    pages: [
        {
            id: 1,
            type: PageTypes.WELCOME,
            mdText: "Welcome to the test"
        },
        {
            id: 2,
            type: PageTypes.FINISH,
        }
    ]
};

export const defaultAddUsersToTraining = {
    trainingId: "",
    loginIds: [],
    priorityRank: 0,
    lastDate: "",
};

export const defaultOrganization = {
    id: "",
    name: "",
    orgLoginId: "",
    trainingIds: [],
};

export const defaultCreateNewUsers = [
    {
        loginId: "",
        name: "",
    }
];

export const languageOptionList = [
    {
        name: "Select Language",
        value: "SELECT_LANGUAGE",
    },
    {
        name: "English",
        value: "ENGLISH"
    },
    {
        name: "Bengali",
        value: "BANGLA"
    },
    {
        name: "Hindi",
        value: "HINDI"
    }
];
export const pageTypeDropDownObjects = {
    WELCOME: {
        name: "Welcome",
        value: "WELCOME"
    },
    SECTION_START: {
        name: "Section Start",
        value: "SECTION_START"
    },
    QUIZ: {
        name: "Quiz",
        value: "QUIZ"
    },
    INFO_VIDEO: {
        name: "Information Video",
        value: "INFO_VIDEO"
    },
    INFO_IMAGE: {
        name: "Information Image",
        value: "INFO_IMAGE"
    },
    INFO_TEXT: {
        name: "Information Text",
        value: "INFO_TEXT"
    },
    QUIZ_RESULTS: {
        name: "Quiz Result",
        value: "QUIZ_RESULTS"
    },
    FINISH: {
        name: "Finish",
        value: "FINISH"
    }
}
export const pageTypeList = [
    pageTypeDropDownObjects.WELCOME,
    pageTypeDropDownObjects.SECTION_START,
    pageTypeDropDownObjects.QUIZ,
    pageTypeDropDownObjects.INFO_VIDEO,
    pageTypeDropDownObjects.INFO_IMAGE,
    pageTypeDropDownObjects.INFO_TEXT,
    pageTypeDropDownObjects.QUIZ_RESULTS,
    pageTypeDropDownObjects.FINISH
];

export const insertablePageTypeList = [
    pageTypeDropDownObjects.SECTION_START,
    pageTypeDropDownObjects.QUIZ,
    pageTypeDropDownObjects.INFO_VIDEO,
    pageTypeDropDownObjects.INFO_IMAGE,
    pageTypeDropDownObjects.INFO_TEXT,
    pageTypeDropDownObjects.QUIZ_RESULTS,
]

export const typeOptionList = [
    {
        name: "Awareness",
        value: "AWARENESS",
    },
    {
        name: "Orientation",
        value: "ORIENTATION",
    }
]

export function getSaveTrainingResponse(status: string, msg: string): IResponseMessage {
    return {
        status,
        msg,
    };
}
export function getPageReferences(pages: IPage[]) {
    let pageReferences = {};
    pages.forEach((page: any) => {
        if (page.type === PageTypes.QUIZ_RESULTS && page.quizPageId !== null) {
            pageReferences[page.quizPageId] = page.id;
        }
    });
    return pageReferences
}
export function shouldDeletePage(deletePageId: number, pageId: number, pageReferences: {[pageId: number]: number}) {
    return (pageId === deletePageId || (pageReferences.hasOwnProperty(deletePageId) && pageReferences[deletePageId] === pageId))
}
export function reArrangePageIds(pages: IPage[], pageReferences: {[pageId: number]: number}) {
    let updatedPageList: IPage[] = [];
    let currentId = 0;
    let newReferences = {};
    pages.forEach((page: any, index) => {
        let pageObj = JSON.parse(JSON.stringify(page));
        if (pageReferences.hasOwnProperty(page.id)) {
            newReferences[page.id] = currentId + 1;
        }
        if (page.hasOwnProperty("quizPageId") && newReferences.hasOwnProperty(page.quizPageId)) {
            pageObj.quizPageId = newReferences[page.quizPageId]
        }
        pageObj.id = ++currentId;
        updatedPageList.push(pageObj)
    });
    return updatedPageList;
}

export function getNewPage(pageType: PageTypes) {
    let obj = {
        id: null,
        type: pageType,
    };
    if (pageType === PageTypes.SECTION_START) {
        obj["mdText"] = "";
    } else if (pageType === PageTypes.INFO_IMAGE || pageType === PageTypes.INFO_VIDEO) {
        obj["mdText"] = "";
        obj[pageType === PageTypes.INFO_IMAGE ? "imageUrl" : "videoUrl"] = "";
    } else if (pageType === PageTypes.INFO_TEXT) {
        obj["mdText"] = "";
    } else if (pageType === PageTypes.QUIZ) {
        obj["passPercent"] = 0;
        obj["questions"] = [];
    } else if (pageType === PageTypes.QUIZ_RESULTS) {
        obj["quizPageId"] = null;
    }
    return obj;
}

export const trainingListMetaColumn = [
    {
        title: "Training Name",
        key: "name",
        width: "120",
        value: (name, trainingDetails) => <Link to={{pathname: `trainings/trainingDetails`, state: {trainingId: trainingDetails.id}}}>{name}</Link>,
        extra: {
            isKey: true,
        },
    },
    {
        title: "type",
        key: "type",
        width: "40",
    },
    {
        title: "Language",
        key: "language",
        width: "40",
    },
    {
        title: "Edited By",
        key: "editedBy",
        width: "40",
    },
    {
        title: "Last Edited",
        key: "lastEdited",
        width: "40",
        value: (val) => getDateStringFormSecond(val),
    },
];
export function getNewQuestion(): IQuestions {
    return {
        id: -1,
        question: "",
        mdExplanation: "",
        mdText: "",
        answers: [],
        options: []
    }
}
export function clickableRoutes(routeList: {name: string; path: string}[]) {
    return (
        <div className={"text-center mt-3"}>
            {routeList.map((route, index) => {
                return (
                    route.path !== "" ? <Link to={route.path} key={index}>{route.name} > </Link> : <span key={index}>{route.name}</span>
                )
            })}
        </div>
    )
}
export function getQuizPageIds(pages: IPage[], quizResultPageIndex: number) {
    let quizPageIdList: number[] = [];
    pages.slice(0, quizResultPageIndex).forEach((page) => {
        if (page.type === PageTypes.QUIZ) {
            quizPageIdList.push(page.id)
        }
    });
    return quizPageIdList;
}