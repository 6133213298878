import React, {useState} from "react";
import Modal from "../common/Modal";
import CSVReader from 'react-csv-reader'
import dispatch from "../../middleware";
import {deleteUsers, getSelectedOrganizations, resetOrganizationResponse} from "../../actions/organization";
import {useDispatch, useSelector} from "react-redux";
import {IState as IOrganizationState} from "../../reducers/organization";
import {IReduxState} from "../../reducers";
import {DownloadCsv} from "./utils";

export default function DeleteUsers(props) {
    let selectedOrganization: IOrganizationState = useSelector((state: IReduxState) => state.organizations);
    const storeDispatch = useDispatch();
    const [isDeleteUsersModalActive, setIsDeleteUsersModalActive] = useState<boolean>(false);
    const [csvData, setCsvData] = useState("");
    const [error, setError] = useState<string>("");

    const handleDeleteUsersSubmit = () => {
        let docIds : string[] = [];
        if (csvData !== "") {
            for (const loginIdElement of csvData) {
                for (const element of Object.values(selectedOrganization.selectedOrganization)) {
                    if (loginIdElement["loginId"] === element.loginId) {
                        docIds.push(element.id);
                    }
                }
            }
            dispatch(storeDispatch, deleteUsers({docIds: docIds})).then((r) => {
                dispatch(storeDispatch, getSelectedOrganizations(props.selectedOrgId));
            });
            setIsDeleteUsersModalActive(false);
        } else {
            setError("Please upload valid csv");
            setTimeout(() => {setError("")}, 5000);
        }
    };

    function downloadSampleDeleteUsersCsv() {
        const csvData: string[] = [];
        csvData.push("loginId");
        csvData.push("test@abc");
        DownloadCsv(csvData, "Delete Users - Sample");
    }

    const csvParseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
            header
                .replace(/\W/g, '_')
    };
    return (
        <div>
            <Modal
                title={"Delete Users"}
                active={isDeleteUsersModalActive}
                onClose={() => setIsDeleteUsersModalActive(false)}
                onSubmit={handleDeleteUsersSubmit}
                cancellable
            >   <label className="float-left">Please upload a csv file. (Download a sample csv file
                <a onClick={() => downloadSampleDeleteUsersCsv()}><u style={{color: "blue"}}><i> here </i></u>)</a>
            </label>
                <CSVReader onFileLoaded={data => setCsvData(data)} parserOptions={csvParseOptions} />
                <br/>
                { error.trim() !== "" &&
                <div className={"text-danger"}>
                    {error}
                </div>}
            </Modal>
            <button style={{textAlign:"left", margin: 20}} className={"btn btn-danger"} onClick={() => setIsDeleteUsersModalActive(true)} type="button">
                Delete Users
            </button>
        </div>
    )
}
