import React from "react";
import TrainingListMeta from "./trainingListMeta";
import CreateTraining from "./createTraining";
import "./styles.css";
import {IState as ITrainingState} from "../../reducers/training";
import {useSelector} from "react-redux";
import {IReduxState} from "../../reducers";
import {fullScreenLoader} from "../../utils/htmlFunctions";

export default function TrainingDashboard(props: any) {
    let training: ITrainingState = useSelector((state: IReduxState) => state.training);
    return (
        <div className="text-center mt-4 pt-4">
            {training.loading && fullScreenLoader()}
            <TrainingListMeta trainings={training.trainingList} path={props.match.url}/>
            <CreateTraining/>
        </div>
    )
}