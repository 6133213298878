import React, {useEffect} from "react";
import "../styles.css";
import {useDispatch, useSelector} from "react-redux";
import dispatch from "../../../middleware";
import {
    getFeedback,
    getTrainingDetails, resetSaveTrainingResponse,
    revertTrainingChanges, saveTraining, setTrainingId,
    updateTrainingDetails
} from "../../../actions/training";
import {IState as ITrainingState} from "../../../reducers/training";
import {IReduxState} from "../../../reducers";
import {fullScreenLoader} from "../../../utils/htmlFunctions";
import {TextField, Select} from "../../../utils/inputFunctions";
import {
    FieldKeyNames,
    InputFieldTypes,
    ITraining,
    IUpdateSelectedTrainingDetails
} from "../../../models";
import {updateSelectedTrainingDetails, languageOptionList, clickableRoutes} from "../utils";
import {getDateStringFormSecond, getParentRoute} from "../../../utils/utilityFunctions";
import {Link, Redirect} from "react-router-dom";

export default function TrainingDetails(props: any) {
    const routes = [{name: "Training List", path: "/trainings"}, {name: "Training Details", path: ""}];
    let training: ITrainingState = useSelector((state: IReduxState) => state.training);
    const trainingId = props.location && props.location.state && props.location.state.trainingId;
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (trainingId && training.trainingId === "") {
            dispatch(storeDispatch, getTrainingDetails(trainingId));
            dispatch(storeDispatch, setTrainingId(trainingId));
        }
    }, []);
    if (trainingId === undefined &&  training.trainingId === "") return <Redirect to={`${getParentRoute(props.match.url)}`}/>;
    function handleInputChange(fieldName: string, value: string | number) {
        const trainingDetails: IUpdateSelectedTrainingDetails = updateSelectedTrainingDetails(training.editableTraining, fieldName, value);
        dispatch(storeDispatch, updateTrainingDetails(trainingDetails.updatedTrainingObj, trainingDetails.reArrangeReferences));
    }
    function handleRevertChange() {
        dispatch(storeDispatch, revertTrainingChanges());
    }
    function handleSaveTraining(training: ITraining, trainingId: string) {
        dispatch(storeDispatch, saveTraining(training, trainingId)).then((r) => {
            setTimeout(() => {
                dispatch(storeDispatch, resetSaveTrainingResponse())
            }, 3000)
        });
    }
    function downloadFeedback(trainingId: string) {
        dispatch(storeDispatch, getFeedback(trainingId));
    }

    return (
        <div className="text-center">
            {training.loading && fullScreenLoader()}
            {clickableRoutes(routes)}
            {Object.keys(training.training).length > 0 &&
            <div className={"container-fluid d-flex justify-content-center"}>
                <div className="col-md-4 box">
                    {/*Will change the below section with a mapping*/}
                    <div className="w-100 d-inline-flex my-3">
                        <span className={"w-25 text-right font-weight-bold mt-18"}>Training Name: </span>
                        <span className={"w-75 mx-3"}>
                            <TextField type={InputFieldTypes.TEXT}
                                value={training.editableTraining.name}
                                onChange={handleInputChange}
                                fieldName={FieldKeyNames.TRAINING_NAME}
                            />
                        </span>
                    </div>

                    <div className="w-100 d-inline-flex my-3">
                        <span className={"w-25 text-right font-weight-bold"}>Edited By: </span>
                        <span className="w-75">{training.editableTraining.editedBy}</span>
                    </div>

                    <div className="w-100 d-inline-flex my-3">
                        <span className={"w-25 text-right font-weight-bold"}>Last Edited: </span>
                        <span className="w-75">{getDateStringFormSecond(training.editableTraining.lastEdited)}</span>
                    </div>

                    <div className="w-100 d-inline-flex my-3">
                        <span className={"w-25 text-right font-weight-bold mt-18"}>Language: </span>
                        <span className={"w-75 mx-3"}>
                            <Select value={training.editableTraining.language}
                                options={languageOptionList}
                                fieldName={FieldKeyNames.TRAINING_LANGUAGE}
                                onChange={handleInputChange}
                            />
                        </span>
                    </div>

                    <div className="w-100 d-inline-flex my-3">
                        <span className={"w-25 text-right font-weight-bold"}>Total Pages: </span>
                        <span className="w-75">{training.editableTraining.pages.length}</span>
                    </div>

                    {training.saveTrainingResponse.status.trim() !== "" &&
                    <div className={training.saveTrainingResponse.status === "SUCCESS" ? "text-success" : "text-danger"}>
                        {training.saveTrainingResponse.msg}
                    </div>}
                    <div className={"w-100 my-4 pt-2"}>
                        <Link to={`${getParentRoute(props.match.url)}/trainingPage`}><button className={"float-left btn-custom mx-2"}>Edit Page</button></Link>
                        <button className={"float-left btn-custom mx-2"} onClick={handleRevertChange}>Revert Change</button>
                        <button className={"float-right btn-custom mx-2"}
                                onClick={() => downloadFeedback(training.trainingId)}
                        >Download feedback</button>
                        <button className={"float-right btn-custom"}
                                onClick={() => handleSaveTraining(training.editableTraining, training.trainingId)}
                        >Save Details</button>
                    </div>
                </div>
            </div>}
        </div>
    )
}