import {
    IActions,
    IAddUsersToTraining,
    ICreateNewUsers,
    IDeleteOrgPayload,
    IDeleteUsersPayload,
    IOrganization
} from "../models";

export enum Actions {
    REQUEST_GET_ALL_ORGANIZATIONS = "REQUEST_GET_ALL_ORGANIZATIONS",
    REQUEST_GET_SELECTED_ORGANIZATION = "REQUEST_GET_SELECTED_ORGANIZATION",
    REQUEST_CREATE_NEW_ORGANIZATION = "REQUEST_CREATE_NEW_ORGANIZATION",
    REQUEST_CREATE_USERS_FOR_ORGANIZATION = "REQUEST_CREATE_USERS_FOR_ORGANIZATION",
    REQUEST_DELETE_ORGANIZATION = "REQUEST_DELETE_ORGANIZATION",
    REQUEST_ASSIGN_TRAINING_TO_USERS = "REQUEST_ASSIGN_TRAINING_TO_USERS",
    REQUEST_DELETE_USERS = "REQUEST_DELETE_USERS",
    REQUEST_ADD_TRAINING_TO_ORGANIZATION = "REQUEST_ADD_TRAINING_TO_ORGANIZATION",
    RESET_ORGANIZATION_RESPONSE = "RESET_ORGANIZATION_RESPONSE",
    RESET_USERLOGIN_DETAILS = "RESET_USERLOGIN_DETAILS",
}
export function getAllOrganizations(): IActions {
    return {
        actionName: Actions.REQUEST_GET_ALL_ORGANIZATIONS,
        type: "GET",
        url: "/getAllOrgs",
    }
}

export function getSelectedOrganizations(id: string): IActions {
    return {
        actionName: Actions.REQUEST_GET_SELECTED_ORGANIZATION,
        type: "GET",
        url: `/getUsersForOrg/${id}`,
    }
}

export function createNewOrganization(insertOrgData: IOrganization): IActions {
    return {
        actionName: Actions.REQUEST_CREATE_NEW_ORGANIZATION,
        type: "POST",
        url: `/insertOrg`,
        body: insertOrgData,
    }
}

export function createUsersForOrganization(createUsersData: ICreateNewUsers, orgId: string): IActions {
    return {
        actionName: Actions.REQUEST_CREATE_USERS_FOR_ORGANIZATION,
        type: "POST",
        url: `/createUsersForOrg/${orgId}`,
        body: createUsersData,
    }
}

export function deleteOrg(orgId: IDeleteOrgPayload): IActions {
    return {
        actionName: Actions.REQUEST_DELETE_ORGANIZATION,
        type: "POST",
        url: `/deleteOrg`,
        body: orgId,
    }
}

export function assignTrainingToUsers(assignTrainingToUsersData: IAddUsersToTraining): IActions {
    return {
        actionName: Actions.REQUEST_ASSIGN_TRAINING_TO_USERS,
        type: "POST",
        url: "/assignTrainingToUsers",
        body: {
            trainingId: assignTrainingToUsersData.trainingId,
            loginIds: assignTrainingToUsersData.loginIds,
            priorityRank: assignTrainingToUsersData.priorityRank,
            lastDate: assignTrainingToUsersData.lastDate,
        },
    }

}

export function deleteUsers(deleteUserDetails: IDeleteUsersPayload): IActions {
    return {
        actionName: Actions.REQUEST_DELETE_USERS,
        type: "POST",
        url: "/deleteUsers",
        body: deleteUserDetails,
    }
}

export function addTrainingToOrg(addTrainingToOrgData: IOrganization, orgId: string): IActions {
    return {
        actionName: Actions.REQUEST_ADD_TRAINING_TO_ORGANIZATION,
        type: "POST",
        url: `/updateOrg/${orgId}`,
        body: addTrainingToOrgData,
    }
}

export function resetOrganizationResponse() {
    return {
        actionName: Actions.RESET_ORGANIZATION_RESPONSE
    }
}

export function resetUserLoginDetails() {
    return {
        actionName: Actions.RESET_USERLOGIN_DETAILS
    }

}