import React from "react";
import {FieldKeyNames, InputFieldTypes, IOptions, IQuestions} from "../../../models";
import {TextArea, TextField} from "../../../utils/inputFunctions";
import {escape, getNewQuestion, unescapeString} from "../utils";

interface IProps {
    passPercent: number;
    questions: IQuestions[];
    handleInputChange: (fieldName: string, value: number | string | IQuestions[]) => void;
}
enum OperationTypes {
    DELETE = "DELETE",
    ADD = "ADD",
}
const indexToKeyMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
};

export default function Quiz(props: IProps) {
    function handleInputChange(fieldName, value) {
        const field = fieldName.split("_")[0];
        const index = Number(fieldName.split("_")[1])
        let qus: any = props.questions;
        if (field === "TEXT") {
            qus[index].mdText = escape(String(value));
        } else if (field === "EXPL") {
            qus[index].mdExplanation = escape(String(value));
        } else if (field === "QUESTION") {
            qus[index].question = String(value);
        } else if (field === "ANS") {
            if(qus[index].answers.includes(String(value))) {
                const indexOfValue = qus[index].answers.indexOf(String(value));
                qus[index].answers.splice(indexOfValue, 1);
            } else {
                qus[index].answers.push(String(value));
            }
        } else if (field === "OPTION") {
            const optionIndex = Number(fieldName.split("_")[2]);
            const optionKey = fieldName.split("_")[3];
            if (value === "" && !qus[index].answers.includes(optionKey)) {
                qus[index].options.splice(optionIndex, 1);
                qus[index].options.forEach((option, i) => {
                    option.key = indexToKeyMap[i];
                });
                let ans: string[] = [];
                qus[index].options.forEach(option => {
                    if (qus[index].answers.includes(option.key)) ans.push(option.key);
                });
                qus[index].answers = ans;
            } else if (value !== "") {
                if (qus[index].options[optionIndex]) {
                    qus[index].options[optionIndex].optionText = String(value);
                } else {
                    qus[index].options.push({
                        optionText: String(value),
                        key: indexToKeyMap[optionIndex],
                    })
                }
            }
        }
        props.handleInputChange(FieldKeyNames.QUIZ, [...qus])
    }
    function getAnswers(ans: string[]) {
        if (ans.length === 0) return <b className={"text-danger"}>N/A</b>;
        return (
            <span>
                {ans.map((a, index) => {
                    return <b className={"m-1 ans-op"} key={index}> {a} </b>
                })}
            </span>
        )
    }
    function handleAddOrDeleteQuestion(operationType: OperationTypes, qus: IQuestions[], questionIndex: number) {
        if (operationType === OperationTypes.DELETE) {
            qus.splice(questionIndex, 1);
        } else if (operationType === OperationTypes.ADD) {
            const newQuestion = getNewQuestion();
            qus.splice(questionIndex + 1, 0, newQuestion);
        }
        qus.forEach((obj, index) => {
            obj.id = index + 1;
        });
        props.handleInputChange(FieldKeyNames.QUIZ, [...qus])
    }
    function getOptionValue(options: IOptions[], key: string) {
        let optionText = "";
        options.forEach((option) => {
            if (option.key === key) {
                optionText = option.optionText;
            }
        });
        return optionText;
    }
    function renderOptions(options: IOptions[], answers: string[], questionIndex: number) {
        let disable = false;
        const optionKeyList = ["A", "B", "C", "D"];
        return optionKeyList.map((key, i) => {
            const isDisabled = disable;
            const optionText = getOptionValue(options, key);
            if (optionText === "" && !disable) {
                disable = true;
            }
            return (
                <td style={{minWidth: 300}} key={i}>
                    <TextField value={optionText}
                               disabled={isDisabled}
                               fieldName={`OPTION_${questionIndex}_${i}_${key}`}
                               onChange={handleInputChange} type={InputFieldTypes.TEXT}
                    />
                    {optionText.trim() !== "" && <input checked={answers.includes(key)} type="checkBox"
                                                        onChange={() => handleInputChange(`ANS_${questionIndex}`, key)}/>}
                </td>
            )
        })
    }
    function renderAddQuestion() {
        return (
            <tbody>
                <tr>
                    <td>
                        Np question has been added!
                        <span className="text-success mx-2 cursor-pointer"
                              onClick={() => handleAddOrDeleteQuestion(OperationTypes.ADD, props.questions, 0)}>Add Question</span>
                    </td>
                </tr>
            </tbody>
        )
    }
    return (
        <div>
            <div className="w-25">
                <TextField value={props.passPercent} label={"Pass Percent (%)"}
                           fieldName={FieldKeyNames.PASS_PERCENT} onChange={props.handleInputChange} type={InputFieldTypes.NUMBER}/>
            </div>
            <table className={"table table-bordered question-section"}>
                <thead>
                    <tr>
                        <th>Qs No</th>
                        <th>Md Text</th>
                        <th>Md Question</th>
                        <th>Answers</th>
                        <th>Option A</th>
                        <th>B</th>
                        <th>C</th>
                        <th>D</th>
                        <th>Md Explanation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                {props.questions.length > 0 ? <tbody>
                    {
                        props.questions.map((obj, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index}</td>
                                    <td style={{minWidth: 430}}>
                                        <TextArea value={obj.mdText.trim() === "" ? "Please Enter Text" : unescapeString(obj.mdText)}
                                                   fieldName={`TEXT_${index}`} onChange={handleInputChange}/>
                                    </td>
                                    <td style={{minWidth: 430}}>
                                        <TextArea value={obj.question.trim() === "" ? "Please Enter Question" : obj.question}
                                                  fieldName={`QUESTION_${index}`} onChange={handleInputChange}/>
                                    </td>
                                    <td style={{minWidth: 100}}>{getAnswers(obj.answers)}</td>
                                    {renderOptions(obj.options, obj.answers, index)}
                                    <td style={{minWidth: 322}}>
                                        <TextArea value={obj.mdExplanation.trim() === "" ? "Please Enter Explanation" : unescapeString(obj.mdExplanation)}
                                                  fieldName={`EXPL_${index}`} onChange={handleInputChange}/>
                                    </td>
                                    <td>
                                        <span className={"text-danger mx-2 cursor-pointer"} onClick={() => handleAddOrDeleteQuestion(OperationTypes.DELETE, props.questions, index)}>Delete</span>
                                        <span className={"text-success mx-2 cursor-pointer"} onClick={() => handleAddOrDeleteQuestion(OperationTypes.ADD, props.questions, index)}>Add</span>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody> : renderAddQuestion()}
            </table>
        </div>
    )
}