import React, {useState} from "react";
import Modal from "../common/Modal";
import {
    FieldKeyNames,
    InputFieldTypes, ITraining,
    IValidationStatus,
} from "../../models";
import {Select, TextField} from "../../utils/inputFunctions";
import {
    defaultValidation,
    languageOptionList,
    typeOptionList,
    validateNewTraining
} from "./utils";
import {useDispatch, useSelector} from "react-redux";
import dispatch from "../../middleware";
import {
    clearInsertTrainingResponse,
    clearTrainingDetails,
    getTrainingMeta,
    insertTraining
} from "../../actions/training";
import {defaultNewTraining} from "./utils";
import {IReduxState} from "../../reducers";

export default function CreateTraining() {
    let loading = useSelector((state: IReduxState) => state.training.loading);
    let insertTrainingResponse = useSelector((state: IReduxState) => state.training.insertTrainingResponse);
    let [isModalActive, setIsModalActive] = useState<boolean>(false);
    let [newTraining, handleNewTraining] = useState<ITraining>(defaultNewTraining);
    let [validation, setValidation] = useState<IValidationStatus>(defaultValidation);
    const storeDispatch = useDispatch();

    function handleSubmit() {
        let validate: IValidationStatus= validateNewTraining(newTraining);
        if (validate.status) {
            dispatch(storeDispatch, insertTraining(newTraining)).then((r) => {
                dispatch(storeDispatch, getTrainingMeta());
                dispatch(storeDispatch, clearTrainingDetails());
            });
        } else setValidation(validate);
    }
    function handleInputChange(fieldName: string, value: string | number) {
        handleNewTraining((prevState) => {
            return ({
                ...prevState,
                [fieldName]: value
            });
        });
        setValidation(defaultValidation);
    }
    function handleModalActive(val: boolean) {
        handleNewTraining(defaultNewTraining);
        setIsModalActive(val);
        dispatch(storeDispatch, clearInsertTrainingResponse());
    }
    return (
        <div className={"mt-4 pt-4"}>
            <Modal
                title={"Training Details"}
                disableSubmit={loading}
                submitTitle={loading ? "Submitting..." : "Submit"}
                active={isModalActive}
                onClose={() => setIsModalActive(false)}
                onSubmit={handleSubmit}
                cancellable
            >
                <div className="form-group">
                    <TextField
                        type={InputFieldTypes.TEXT}
                        label={"Training Name"}
                        value={newTraining.name}
                        onChange={handleInputChange}
                        error={validation.type === FieldKeyNames.NAME ? validation.msg : ""}
                        fieldName={FieldKeyNames.NAME}
                    />
                    <Select
                        label={"Language"}
                        value={newTraining.language}
                        options={languageOptionList}
                        fieldName={FieldKeyNames.LANGUAGE}
                        error={validation.type === FieldKeyNames.LANGUAGE ? validation.msg : ""}
                        onChange={handleInputChange}
                    />
                    <Select
                        label={"Type"}
                        value={newTraining.type}
                        options={typeOptionList}
                        fieldName={FieldKeyNames.TYPE}
                        error={validation.type === FieldKeyNames.TYPE ? validation.msg : ""}
                        onChange={handleInputChange}
                    />
                    {insertTrainingResponse.trim() !== "" &&
                    <div className={insertTrainingResponse === "SUCCESS" ? "text-success" : "text-danger"}>
                        {insertTrainingResponse === "SUCCESS" ? "Training created successfully" : "Some error occurred!"}
                    </div>}
                </div>
            </Modal>
            <div className="form-group" style={{textAlign:"center"}}>
                <button className={"btn btn-primary"} onClick={() => handleModalActive(true)} type="button">
                    Create New Training
                </button>
            </div>
        </div>
    )
}