import React, { useState } from "react";
import {ILoginDetails, FieldKeyNames, InputFieldTypes, IValidationStatus} from "../models";
import {validateLogin} from "./utils";
import {TextField} from "../utils/inputFunctions";
import {handleLogin} from "../actions/login";
import {useDispatch, useSelector} from "react-redux";
import dispatch from "../middleware";
import {IState as ILoginState} from "../reducers/login";
import {IReduxState} from "../reducers";
import "./styles.css";

export default function Login() {
    let loginState: ILoginState = useSelector((state: IReduxState) => state.login);
    const [loginDetails, updateLoginDetails] = useState<ILoginDetails>({userId: "", password: ""});
    const [validation, handleValidationResultChange] = useState<IValidationStatus>({status: false, msg: "", type: ""});
    const storeDispatch = useDispatch();

    function handleLoginDetailsChange(fieldName: string, value: string | number) {
        updateLoginDetails((prevState) => {
            return ({
                ...prevState,
                [fieldName]: value
            });
        })
    }
    function handleSubmit(userId: string, password: string) {
        const checkValidation: IValidationStatus = validateLogin(userId, password);
        if (checkValidation.status) {
            const base64String: string = window.btoa(loginDetails.userId+":"+loginDetails.password);
            dispatch(storeDispatch, handleLogin(base64String));
        }
        handleValidationResultChange(() => checkValidation)
    }
    return (
        <div className="container-fluid d-flex justify-content-center login">
           <div className="login-card col-md-4">
                <div className="text-center mt-2">
                    <h3>Welcome</h3>
                </div>
                <div className="mt-4">
                    {<TextField
                        type = {InputFieldTypes.TEXT}
                        value = {loginDetails.userId}
                        label = {"Please enter your login id"}
                        fieldName = {FieldKeyNames.USER_ID}
                        error = {!validation.status && validation.type === FieldKeyNames.USER_ID ? validation.msg : ""}
                        onChange = {handleLoginDetailsChange}
                    />}
                    {<TextField
                        type = {InputFieldTypes.PASSWORD}
                        value = {loginDetails.password}
                        label = {"Please enter your password"}
                        fieldName = {FieldKeyNames.PASSWORD}
                        error = {!validation.status && validation.type === FieldKeyNames.PASSWORD ? validation.msg : ""}
                        onChange = {handleLoginDetailsChange}
                    />}
                    {loginState.loginErrorMsg.trim() !== "" && <div className={"text-danger"}>
                        {loginState.loginErrorMsg}
                    </div>}
                    <button className="submit-login mt-4"
                        onClick={() => handleSubmit(loginDetails.userId, loginDetails.password)}
                        disabled={loginState.loading}
                    >
                        {loginState.loading ? "Logging In..." : "Log In"}
                    </button>
                </div>
           </div>
        </div>
    )
}