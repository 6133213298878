import React, {useState} from "react";
import {Select} from "../../utils/inputFunctions";
import Modal from "../common/Modal";
import dispatch from "../../middleware";
import {addTrainingToOrg, getAllOrganizations, resetOrganizationResponse} from "../../actions/organization";
import {IState as IOrganizationState} from "../../reducers/organization";
import {useDispatch, useSelector} from "react-redux";
import {IReduxState} from "../../reducers";
import {IState as ITrainingState} from "../../reducers/training";
import {IDropDownOption, IOrganization} from "../../models";
import {defaultOrganization} from "../training/utils";

export default function AddTrainingToUsers(props) {
    const [addTrainingToOrganization, setAddTrainingToOrganization] = useState<boolean>(false);
    let selectedOrganization: IOrganizationState = useSelector((state: IReduxState) => state.organizations);
    let training: ITrainingState = useSelector((state: IReduxState) => state.training);
    const [trainingName, setTrainingName] = useState<string>("Select");
    const [trainingId, setTrainingId] = useState<string>("");
    const [error, setError] = useState<string>("");
    const storeDispatch = useDispatch();

    function trainingNameOptions() {
        let options: IDropDownOption[] = [];
        for (const element of training.trainingList) {
            options.push({
                name : element.name,
                value: element.name,
            })
        }
        options.push(
            {name: "Select", value: "Select"}
        );
        return options;
    }
    const trainingNameList: IDropDownOption[] = trainingNameOptions();

    const handleAddTrainingToOrganization = () => {
        let addTrainingToOrganizationData: IOrganization = defaultOrganization;
        if (trainingName !== "Select") {
            for (const element of selectedOrganization.organizations) {
                if (element.id === props.selectedOrgId) {
                    let trainingIds = JSON.parse(JSON.stringify(element.trainingIds));
                    trainingIds.push(trainingId);
                    addTrainingToOrganizationData = {
                        id: element.id,
                        name: element.name,
                        orgLoginId: element.orgLoginId,
                        trainingIds: trainingIds
                    };
                }
            }
            dispatch(storeDispatch, addTrainingToOrg(addTrainingToOrganizationData, props.selectedOrgId)).then((r) => {
                    dispatch(storeDispatch, getAllOrganizations());
            });
            setAddTrainingToOrganization(false);
        } else {
            setError("Please select the training name")
        }
    };

    function selectedTrainingFieldChange(key, value) {
        for (const element of training.trainingList) {
            if (element.name === value) {
                setTrainingId(element.id);
            }
        }
        setTrainingName(value);
    }
    return (
        <div>
        <Modal
            title={"Add Training To Organization"}
            active={addTrainingToOrganization}
            onClose={() => setAddTrainingToOrganization(false)}
            onSubmit={handleAddTrainingToOrganization}
            cancellable
        >
            <Select
                label={"Training Name"}
                value={trainingName}
                options={trainingNameList}
                fieldName={trainingName}
                onChange={selectedTrainingFieldChange}
            />
            { error.trim() !== "" &&
            <div className={"text-danger"}>
                {error}
            </div>}
        </Modal>
            <button style={{textAlign:"left", margin: 20}} className={"btn btn-primary"} onClick={() => setAddTrainingToOrganization(true)} type="button">
            Add Training To Organization
            </button>
        </div>
    )
}