import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {IAddUsersToTraining, InputFieldTypes, ITrainingMeta} from "../../models";
import Modal from "../common/Modal";
import {TextField} from "../../utils/inputFunctions";
import {defaultAddUsersToTraining} from "../training/utils";
import dispatch from "../../middleware";
import {assignTrainingToUsers, resetOrganizationResponse} from "../../actions/organization";
import CSVReader from 'react-csv-reader'
import {DownloadCsv} from "./utils";

export default function TrainingTable(props) {
    const storeDispatch = useDispatch();
    const [trainingId, setTrainingId] = useState<string>("");
    const [addingTrainingToUsers, setAddingTrainingToUsers] = useState<boolean>(false);
    const [lastDate, setLastDate] = useState<string>("");
    const [addUsersToTraining, handleAddUsersToTraining] = useState<IAddUsersToTraining>(defaultAddUsersToTraining);
    const [csvData, setCsvData] = useState("");
    const [error, setError] = useState<string>("");

    const trainingTableOnClick = (training: ITrainingMeta) => {
        setAddingTrainingToUsers(true);
        setTrainingId(training.id);
    };

    function handleInputChange(fieldName: string, value: string | number) {
        handleAddUsersToTraining((prevState) => {
            return ({
                ...prevState,
                [fieldName]: value
            });
        });
    }
    const csvParseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
            header
                .replace(/\W/g, '_')
    };
    function handleDateInputChange(event) {
        setLastDate(event.target.value);
    }

    function getLoginIds(csvLoginIds: Object) {
        let loginIds: string[] = [];
        for (const element of Object(csvLoginIds)) {
            loginIds.push(Object.values(element)[0] as string);
        }
        return loginIds;
    }

    const handleAddUsersToTrainingSubmit = () => {
        if (csvData !== "" && trainingId !== "" && addUsersToTraining.priorityRank !== 0) {
            const assignTrainingToUsersData: IAddUsersToTraining = {
                trainingId: trainingId,
                loginIds: getLoginIds(csvData),
                priorityRank: Number(addUsersToTraining.priorityRank),
                lastDate: lastDate,
            };
            dispatch(storeDispatch, assignTrainingToUsers(assignTrainingToUsersData)).then((r) => {
                setTimeout(() => {
                    dispatch(storeDispatch, resetOrganizationResponse())
                }, 5000);
            });
            setAddingTrainingToUsers(false);
        } else {
            setError("Please upload the valid csv / please enter all the fields");
            setTimeout(() => {setError("")}, 5000);
        }
    };

    function downloadSampleLoginIdCsv() {
        const csvData: string[] = [];
        csvData.push("LoginIds");
        csvData.push("test@abc");
        DownloadCsv(csvData, "Add Training To Org - Sample");
    }
    return (
        <div>
        <table className={"w-100 h-50 table-bordered"}>
            <thead>
            <tr>
                <th style={{height: 50}}>Index</th>
                <th style={{height: 50}}>Training Name</th>
            </tr>
            </thead>
            <tbody>
            { props.selectedOrgTrainingList.length ?
                props.selectedOrgTrainingList.map((trainingDetails, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <a style={{color: "blue", textDecoration: "underline"}}
                                   onClick={() => trainingTableOnClick(trainingDetails)}><u><i>{trainingDetails.name}</i></u></a>
                            </td>
                        </tr>
                    )
                }) : <tr><td style={{height: 50}} colSpan={2}><b>No Results</b></td></tr>
            }
            </tbody>
        </table>
            <Modal
                title={"Adding Training To Users"}
                active={addingTrainingToUsers}
                onClose={() => setAddingTrainingToUsers(false)}
                onSubmit={handleAddUsersToTrainingSubmit}
                cancellable
            ><label className="float-left">Login Ids</label>
            <CSVReader onFileLoaded={data => setCsvData(data)} parserOptions={csvParseOptions}/>
                <label className="float-left">Please upload a csv file. (Download a sample csv file
                    <a onClick={() => downloadSampleLoginIdCsv()}><u style={{color: "blue"}}><i> here </i></u>)</a>
                </label
                >
            <TextField
                type = {InputFieldTypes.NUMBER}
                value = {addUsersToTraining.priorityRank}
                label = {"Please enter priority Rank"}
                fieldName = {'priorityRank'}
                onChange = {handleInputChange}
            />
            <label className="float-left">Expiry Date</label>
            <input
                defaultValue={addUsersToTraining.lastDate || ""}
                type="date"
                className="form-control"
                onChange={(e) => handleDateInputChange(e)}/>
                { error.trim() !== "" &&
                <div className={"text-danger"}>
                    {error}
                </div>}
            </Modal>
        </div>
    )
}