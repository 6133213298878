import React from 'react';
import {ISelect, ITextArea, ITextField} from "../models";
import "./styles.css";

export function TextField(props: ITextField) {
    return (
        <div className="input-style mt-2 mb-2">
            <div className="float-left">
                <span>{props.label}</span>
            </div>
            {props.error && props.error.trim() !== "" && <div className="float-right error-color">
                {props.error}
            </div>}
            <input type={props.type}
                className={`${props.disabled ? "cursor-na" : ""} input-text ${props.error && props.error.trim() !== "" ? "error-border" : null}`}
                value={props.value !== null ? props.value : ""}
                onChange={(e) => props.onChange(props.fieldName, e.target.value)}
                disabled={props.disabled ? props.disabled : false}
                placeholder={props.placeholder ? props.placeholder : ""}
            />
        </div>
    )
}

export function Select(props: ISelect) {
    return (
        <div className="input-style mt-2 mb-2">
            <div className="float-left">
                <span>{props.label}</span>
            </div>
            {props.error && props.error.trim() !== "" && <div className="float-right error-color">
                {props.error}
            </div>}
            <select
                value={props.value !== null ? props.value : ""}
                className={`input-text ${props.error && props.error.trim() !== "" ? "error-border" : null}`}
                onChange={(e) => props.onChange(props.fieldName, e.target.value)}
                disabled={props.disabled ? props.disabled : false}
            >
                {props.options.map((option, index: number) => {
                    return <option key={index} value={option.value}>{option.name}</option>
                })}
            </select>
        </div>
    )
}

export function TextArea(props: ITextArea) {
    return (
        <div className="input-style mt-2 mb-2">
            <div className="float-left">
                <span>{props.label}</span>
            </div>
            {props.error && props.error.trim() !== "" && <div className="float-right error-color">
                {props.error}
            </div>}
            <textarea
                   className={`input-text ${props.error && props.error.trim() !== "" ? "error-border" : null}`}
                   value={props.value !== null ? props.value : ""}
                   onChange={(e) => props.onChange(props.fieldName, e.target.value)}
                   disabled={props.disabled ? props.disabled : false}
                   placeholder={props.placeholder ? props.placeholder : ""}
                   style={{minHeight: 100}}
            >
            </textarea>
        </div>
    )
}