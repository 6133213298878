import React, {useState} from "react";
import {Select} from "../../../utils/inputFunctions";
import {FieldKeyNames} from "../../../models";

interface IProps {
    quizPageId: string | null;
    quizPageIdList: number[];
    handleInputChange: (fieldName: string, value: number | string) => void;
    pageReferences: {[pageId: number]: number};
}
export default function QuizResult(props: IProps) {
    const [hasExistingQuizResult, updateHasExistingQuizResult] = useState<boolean>(false);
    function getOptionValue(fieldName: string, value: string | number) {
        if (props.pageReferences.hasOwnProperty(Number(value))) {
            updateHasExistingQuizResult(true);
        } else {
            updateHasExistingQuizResult(false);
            props.handleInputChange(fieldName, value);
        }
    }
    const options = props.quizPageIdList.map((id) => {
        return {name: `Quiz Id: ${id}`, value: String(id)}
    });
    options.splice(0, 0, {name: "Select Quiz Page Id", value: "SELECT_QUIZ_PAGE_ID"});

    return (
        <div className={"text-center"}>
            {hasExistingQuizResult && <div className={"text-danger"}>This quiz id already has a corresponding quiz result page</div>}
            <div className={"w-25"}>
                <Select value={props.quizPageId === null ? "SELECT_QUIZ_PAGE_ID" : props.quizPageId}
                        fieldName={FieldKeyNames.QUIZ_ID} label={"Select Quiz Id for this page"}
                        options={options} onChange={getOptionValue}
                />
            </div>
        </div>
    )
}