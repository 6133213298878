import {DownloadCsv} from "./utils";

function getTrainings(user, status){
    const trainings = user.hasOwnProperty('trainings') ? user.trainings :[];
    return trainings.filter(t => t.status === status).length;
}

export function handleExportToCsvOrgUsers(orgUsers){
    const colNames = ["name", "loginId"];
    const calculatedColumns = {
        "incompleteTrainings": (user) => getTrainings(user, "INCOMPLETE"),
        "completedTrainings": (user) => getTrainings(user, "COMPLETED")
    }
    return handleExportToCsv(orgUsers, colNames, calculatedColumns, "UserDetails");
}

export function handleExportToCsvFeedbacks(feedbacks: Array<any>){
    const colNames = ["text", "loginId"];
    return handleExportToCsv(feedbacks, colNames, {}, "Feedbacks");
}

export function handleExportToCsv(objectList: Array<any>, colNames, calculatedColumns, csvName) {
    const calculatedColumnsList = Object.keys(calculatedColumns);
    const csv: string[] = [];
    csv.push(colNames.concat(calculatedColumnsList).join(","));
    
    for (let i = objectList.length - 1; i >= 0; i--) {
        let csvRow = colNames.map((key) => {
            if (objectList[i].hasOwnProperty(key) && objectList[i][key] !== "") {
                const val = (objectList[i][key] || "").toString().replace(/^"(.*)"$/, '$1');
                return `${val}`;
            }
            return "";

        });
        
        const calculatedColumnValues = calculatedColumnsList.map(c => calculatedColumns[c](objectList[i]));
        csvRow = csvRow.concat(calculatedColumnValues)
        csv.push(csvRow.join(","));
    }
    DownloadCsv(csv, csvName);
}