import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewOrganization,
    getAllOrganizations,
    getSelectedOrganizations,
    resetOrganizationResponse
} from "../../actions/organization";
import {IReduxState} from "../../reducers";
import "./styles.css";
import Table from "../../components/common/Table";
import dispatch from "../../middleware";
import {IState as IOrganizationState} from "../../reducers/organization";
import Modal from "../common/Modal";
import {IOrganization, ISelectedOrganization} from "../../models";
import {Link, RouteComponentProps} from "react-router-dom";
import {fullScreenLoader} from "../../utils/htmlFunctions";

const tableColumns = [
        {
            title: "Organization Name",
            key: "name",
            width: "50",
            value: (name, org) => <Link to={{pathname: `organizations/${org.id}`, state: {orgId: org.id, orgName: org.name}}}>{name}</Link>,
            extra: {
                isKey: true,
            },
        },
        {
            title: "Org login id",
            key: "orgLoginId",
            width: "170",
        },
    ];

export default function Organizations(props: RouteComponentProps) {
    let allOrganizations: IOrganizationState = useSelector((state: IReduxState) => state.organizations);
    let selectedOrganization: ISelectedOrganization = useSelector((state: IReduxState) => state.organizations.selectedOrganization);
    const storeDispatch = useDispatch();
    const [isModalActive, setIsModalActive] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [loginId, setLoginId] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        dispatch(storeDispatch, getAllOrganizations()).then((r) => {
            setTimeout(() => {
                dispatch(storeDispatch, resetOrganizationResponse());
            }, 3000);
        });
        dispatch(storeDispatch, getSelectedOrganizations(selectedOrganization.id));
    }, [storeDispatch, selectedOrganization.id]);

    const handleSubmit = () => {
        if (name !== "" || loginId !== "" ) {
            setError("");
            const createNewOrganizationData: IOrganization = {
                name: name,
                orgLoginId: loginId,
                trainingIds: [],
            };
            dispatch(storeDispatch, createNewOrganization(createNewOrganizationData)).then((r) => {
                dispatch(storeDispatch, getAllOrganizations());
            });
            setIsModalActive(false);
        } else {
            setError("Please enter all the fields")
        }
    };

    const handleClose = () => {
        setIsModalActive(false)
    };
    return (
        <div>
            {allOrganizations.loading && fullScreenLoader()}
            <h2 style={{textAlign:"center"}}>Organizations</h2>
            <div className="container-fluid d-flex justify-content-center login">
                <Table
                    data={allOrganizations.organizations}
                    columns={tableColumns}
                    search
                    selectedRow={selectedOrganization ? selectedOrganization.id : null}
                    multiColumnSearch
                    pagination
                    sortIndicator
                />
            </div>
            <Modal
                title={"Create New Organization"}
                active={isModalActive}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancellable
            >
                <div className="form-group">
                    <div>
                        <label>Name</label>
                    <input
                        type="text"
                        value={name}
                        name="name"
                        onChange={e => setName(e.target.value)}
                        className="form-control"
                    /></div>
                    <div><label>Org Id</label>
                    <input
                        type="text"
                        value={loginId}
                        name="Login Id"
                        onChange={e => setLoginId(e.target.value)}
                        className="form-control"
                    /></div>
                </div>
                { error.trim() !== "" &&
                <div className={"text-danger"}>
                    {error}
                </div>}
            </Modal>
            <div className="form-group" style={{textAlign:"center"}}>
                { allOrganizations.organizationResponse.status.trim() !== "" &&
                <div className={allOrganizations.organizationResponse.status === "SUCCESS" ? "text-success" : "text-danger"}>
                    {allOrganizations.organizationResponse.msg}
                </div>}<br/>
                <button className={"btn btn-primary"} onClick={() => setIsModalActive(true)} type="button">
                    Create New Organization
                </button>
            </div>
            </div>
    )
}