export default null;

export enum InputFieldTypes {
    TEXT = "text",
    NUMBER = "number",
    PASSWORD = "password",
}
export enum TrainingType {
    AWARENESS = "AWARENESS",
    ORIENTATION = "ORIENTATION",
}

//Will remove the TRAINING_NAME & TRAINING_LANGUAGE. Instead will use NAME & LANGUAGE
export enum FieldKeyNames {
    USER_ID = "userId",
    PASSWORD = "password",
    TRAINING_NAME = "trainingName",
    TRAINING_LANGUAGE = "trainingLanguage",
    NAME = "name",
    LANGUAGE = "language",
    TYPE = "type",
    PAGE_INDEX = "pageIndex",
    PAGE_TYPE = "pageType",
    MD_TEXT = "mdText",
    VIDEO_URL = "videoUrl",
    IMG_URL = "imageUrl",
    PASS_PERCENT = "passPercent",
    QUIZ = "quiz",
    QUIZ_ID = "quizId",
}

export interface IValidationStatus {
    status: boolean;
    msg: string;
    type: string;
}
export interface ILoginDetails {
    userId: string;
    password: string;
}

export interface ITextField {
    label?: string;
    error?: string;
    value: string | number;
    disabled?: boolean;
    placeholder?: string;
    fieldName: string;
    onChange: (fieldName: string, value: string | number) => void;
    type: string;
}
export interface ITextArea {
    label?: string;
    error?: string;
    value: string | number;
    disabled?: boolean;
    placeholder?: string;
    fieldName: string;
    onChange: (fieldName: string, value: string | number) => void;
}
export interface IDropDownOption {
    name: string | number;
    value: string | number;
}
export interface ISelect {
    label?: string;
    error?: string;
    value: string | number;
    options: IDropDownOption[]
    disabled?: boolean;
    fieldName: string;
    onChange: (fieldName: string, value: string | number) => void;
}

export interface IActions {
    actionName: string;
    body?: object;
    type?: string;
    url?: string;
    authType?: string;
    token?: string;
}

export interface IOrganization {
    id?: string;
    name: string;
    orgLoginId: string;
    trainingIds: string[];
}

export interface ITrainings {
    completionTimeStamp: number;
    id: string;
    status: string;
    priorityRank: number;
    lastDate: string;
}

export interface ISelectedOrganization {
    id: string;
    name: string;
    loginId: string;
    trainings: ITrainings[];
}

export interface ITrainingMeta {
    editedBy: string;
    id: string;
    lastEdited: number;
    name: string;
    type: TrainingType,
    language: string,
}
export enum PageTypes {
    WELCOME = "WELCOME",
    SECTION_START = "SECTION_START",
    QUIZ = "QUIZ",
    INFO_VIDEO = "INFO_VIDEO",
    INFO_IMAGE = "INFO_IMAGE",
    INFO_TEXT = "INFO_TEXT",
    QUIZ_RESULTS = "QUIZ_RESULTS",
    FINISH = "FINISH"
}
export interface IOptions {
    key: string;
    optionText: string;
}
export interface IQuestions {
    id: number;
    question: string;
    mdExplanation: string;
    mdText: string;
    answers: string[];
    options: IOptions[];

}
export interface IIntroductionPage {
    id: number;
    type: PageTypes;
    mdText: string;
}
export interface IInfoVideoPage {
    id: number;
    mdText: string;
    type: PageTypes;
    videoUrl: string;
}
export interface IQuizPage {
    id: number;
    passPercent: number;
    type: PageTypes;
    questions: IQuestions[];
}

export interface IQuizResultPage {
    id: number;
    type: PageTypes;
    quizPageId: number;
}

export interface IInfoTextPage {
    id: number;
    type: PageTypes;
    mdText: string;
}

export interface IInfoImagePage {
    id: number;
    imageUrl: string;
    mdText: string;
    type: PageTypes;
}

export interface IFinishPage {
    id: number;
    type: PageTypes;
}
export type IPage = IIntroductionPage | IInfoVideoPage | IInfoTextPage | IInfoImagePage | IQuizPage | IQuizResultPage | IFinishPage;

export interface ITraining {
    editedBy: string;
    language: string;
    lastEdited: number;
    name: string;
    pages: IPage[];
    type: TrainingType;
}
export interface IUpdateSelectedTrainingDetails {
    updatedTrainingObj: ITraining,
    reArrangeReferences: boolean;
}
export interface IResponseMessage {
    status: string;
    msg: string;
}

export interface IAddUsersToTraining {
    trainingId: string,
    loginIds: string[],
    priorityRank: number,
    lastDate: string,
}

export interface IUserLoginDetails {
    loginId: string;
    password: string;
}

export interface INewUser {
    loginId: string;
    name: string;
}

export interface ICreateNewUsers {
    users: INewUser[];
}

export interface IDeleteOrgPayload {
    orgId: string;
}

export interface IDeleteUsersPayload {
    docIds: string[];
}
