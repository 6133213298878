import React, {useState} from "react";
import {downloadLoginDetails} from "./loginDetailsCsv";
import Modal from "../common/Modal";
import dispatch from "../../middleware";
import {createUsersForOrganization, getSelectedOrganizations, resetUserLoginDetails} from "../../actions/organization";
import {IState as IOrganizationState} from "../../reducers/organization";
import {useDispatch, useSelector} from "react-redux";
import {IReduxState} from "../../reducers";
import CSVReader from 'react-csv-reader'
import {INewUser} from "../../models";
import {defaultCreateNewUsers} from "../training/utils";
import {DownloadCsv} from "./utils";

export default function CreateNewUsers(props) {
    let selectedOrganization: IOrganizationState = useSelector((state: IReduxState) => state.organizations);
    const storeDispatch = useDispatch();
    const [csvData, setCsvData] = useState<INewUser[]>(defaultCreateNewUsers);
    const [isModalActive, setIsModalActive] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleSubmit = () => {
        if (csvData !== defaultCreateNewUsers) {
            const createNewUsersData = {
                users: (csvData)
            };
            dispatch(storeDispatch, createUsersForOrganization(createNewUsersData, props.selectedOrgId));
        } else {
            setError("Please upload valid csv");
            setTimeout(() => {setError("")}, 5000);
        }
    };

    const handleClose = () => {
        dispatch(storeDispatch, getSelectedOrganizations(props.selectedOrgId));
        dispatch(storeDispatch, resetUserLoginDetails());
        setIsModalActive(false);
    };

    function downloadSampleCreateNewUserCsv() {
        const csvRow: any[] = [];
        const titles: string[] = ["loginId", "name"];
        const csvData: string[] = ["kumar@ac", "Kumar"];
        csvRow.push(titles);
        csvRow.push(csvData);
        DownloadCsv(csvRow, "Create New Users Sample csv");
    }

    const csvParseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
            header
                .replace(/\W/g, '_')
    };
    return (
        <div>
        <Modal
            title={"Create New Users"}
            active={isModalActive}
            onClose={handleClose}
            onSubmit={handleSubmit}
            disableSubmit={selectedOrganization && selectedOrganization.userloginDetails && selectedOrganization.userloginDetails.length > 0}
            cancellable
        >   <label className="float-left">Please upload a csv file. (Download a sample csv file
            <a onClick={() => downloadSampleCreateNewUserCsv()}><u style={{color: "blue"}}><i> here </i></u>)</a>
        </label>
            <CSVReader onFileLoaded={data => setCsvData(data)} parserOptions={csvParseOptions} />
            <br/>
            <table className={"w-100 h-50 table-bordered"}>
                <thead>
                <tr>
                    <th style={{height: 50}}>LoginId</th>
                    <th style={{height: 50}}>Password</th>
                </tr>
                </thead>
                <tbody>
                {selectedOrganization && selectedOrganization.userloginDetails &&
                selectedOrganization.userloginDetails.map((userDetails, index) => {
                    return (
                        <tr key={index}>
                            <td style={{height: 50}}>{userDetails.loginId}</td>
                            <td style={{height: 50}}>
                                {userDetails.password}
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table><br/> {selectedOrganization.userloginDetails.length ?
                <button style={{textAlign:"center"}} className={"btn btn-primary"} onClick={() => downloadLoginDetails(selectedOrganization.userloginDetails)} type="button">
                    Download Login Details
                </button> : ""}
            <br/>
            { error.trim() !== "" &&
            <div className={"text-danger"}>
                {error}
            </div>}
            {
                selectedOrganization.loading &&
                    <div className={"text-info"}>
                        Loading....
                    </div>
            }
        </Modal>
            <button style={{textAlign:"left", margin: 20}} className={"btn btn-primary"} onClick={() => setIsModalActive(true)} type="button">
                Create New Users
            </button>
        </div>
    )
}