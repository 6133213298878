import React, {useEffect} from "react";
import {clearTrainingDetails, getTrainingMeta} from "../../actions/training";
import dispatch from "../../middleware";
import {useDispatch} from "react-redux";
import "./styles.css";
import {ITrainingMeta} from "../../models";
import Table from "../../components/common/Table";
import {trainingListMetaColumn} from "./utils";

interface IProps {
    trainings: ITrainingMeta[];
    path: string;
}
export default function TrainingListMeta(props: IProps) {
    const storeDispatch = useDispatch();

    useEffect(() => {
        dispatch(storeDispatch, getTrainingMeta());
        dispatch(storeDispatch, clearTrainingDetails());
    }, []);
    return (
        <div className="row container-fluid d-flex justify-content-center text-center">
            <div className="col-md-10 text-left training-meta">
                <h3>Training List</h3>
                <Table
                    data={props.trainings}
                    columns={trainingListMetaColumn}
                    search
                    multiColumnSearch
                    pagination
                    sortIndicator
                />
            </div>
        </div>
    )
}