import {FieldKeyNames, IValidationStatus} from "../models";
import {userMessages} from "../utils/userMessages";

export function validateLogin(userId: string, password: string): IValidationStatus {
    const validation: IValidationStatus = {
        status: false,
        type: "",
        msg: "",
    };
    if(userId.trim() === "") {
        validation.type = FieldKeyNames.USER_ID;
        validation.msg = userMessages.MANDATORY;
    } else if (password.trim() === "") {
        validation.type = FieldKeyNames.PASSWORD;
        validation.msg = userMessages.MANDATORY;
    } else {
        validation.status = true;
    }
    return validation;
}

export default null;