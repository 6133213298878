import {IActions, ITraining, PageTypes} from "../models";

export enum Actions {
    REQUEST_TRAININGS = "REQUEST_TRAININGS",
    REQUEST_TRAINING_DETAILS = "REQUEST_TRAINING_DETAILS",
    CLEAR_TRAINING_DETAILS = "CLEAR_TRAINING_DETAILS",
    UPDATE_TRAINING_DETAILS = "UPDATE_TRAINING_DETAILS",
    REVERT_TRAINING_DETAILS_CHANGE = "REVERT_TRAINING_DETAILS_CHANGE",
    REQUEST_SAVE_TRAINING = "REQUEST_SAVE_TRAINING",
    REQUEST_INSERT_TRAINING = "REQUEST_INSERT_TRAINING",
    CLEAR_INSERT_TRAINING_RESPONSE = "CLEAR_INSERT_TRAINING_RESPONSE",
    DELETE_CURRENT_PAGE = "DELETE_CURRENT_PAGE",
    INSERT_PAGE = "INSERT_PAGE",
    RESET_SAVE_TRAINING_RESPONSE = "RESET_SAVE_TRAINING_RESPONSE",
    SET_TRAINING_ID = "SET_TRAINING_ID",
    REQUEST_GET_FEEDBACK = "REQUEST_GET_FEEDBACK",
}
export function getTrainingMeta(): IActions {
    return {
        actionName: Actions.REQUEST_TRAININGS,
        type: "GET",
        url: "/getTrainingMeta",
    }
}
export function getTrainingDetails(trainingId: string): IActions {
    return {
        actionName: Actions.REQUEST_TRAINING_DETAILS,
        type: "GET",
        url: `/getTraining/${trainingId}`,
    }
}
export function clearTrainingDetails(): IActions {
    return {
        actionName: Actions.CLEAR_TRAINING_DETAILS
    }
}
export function updateTrainingDetails(training: ITraining, reArrangeReferences: boolean): IActions {
    return {
        actionName: Actions.UPDATE_TRAINING_DETAILS,
        body: {training, reArrangeReferences},
    }
}
export function revertTrainingChanges() {
    return {
        actionName: Actions.REVERT_TRAINING_DETAILS_CHANGE
    }
}
export function setTrainingId(trainingId: string) {
    return {
        actionName: Actions.SET_TRAINING_ID,
        body: {trainingId}
    }
}
export function saveTraining(training: ITraining, trainingId: string) {
    return {
        actionName: Actions.REQUEST_SAVE_TRAINING,
        type: "POST",
        url: `/updateTraining/${trainingId}`,
        body: training,
    }
}
export function insertTraining(training) {
    return {
        actionName: Actions.REQUEST_INSERT_TRAINING,
        type: "POST",
        url: "/insertTraining",
        body: training,
    }
}
export function clearInsertTrainingResponse() {
    return {
        actionName: Actions.CLEAR_INSERT_TRAINING_RESPONSE
    }
}
export function resetSaveTrainingResponse() {
    return {
        actionName: Actions.RESET_SAVE_TRAINING_RESPONSE
    }
}

export function deleteCurrentPage(pageId: number) {
    return {
        actionName: Actions.DELETE_CURRENT_PAGE,
        body: {pageId}
    }
}
export function insertNewPage(currentPageIndex: number, pageType: PageTypes | string) {
    return {
        actionName: Actions.INSERT_PAGE,
        body: {currentPageIndex, pageType}
    }
}
export function getFeedback(trainingId: string): IActions {
    return {
        actionName: Actions.REQUEST_GET_FEEDBACK,
        type: "GET",
        url: `/getFeedbacksForTraining/${trainingId}`,
    }
}