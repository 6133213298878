import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getSelectedOrganizations
} from "../../actions/organization";
import {IReduxState} from "../../reducers";
import "./styles.css";
import Table from "../../components/common/Table";
import dispatch from "../../middleware";
import {IState as IOrganizationState} from "../../reducers/organization";
import {getTrainingMeta} from "../../actions/training";
import CreateNewUsers from "./createNewUsers";
import AddTrainingToUsers from "./addTrainingToOrganization";
import TrainingTable from "./trainingTable";
import DeleteUsers from "./deleteUsers";
import DeleteOrganization from "./deleteOrganization";
import {fullScreenLoader} from "../../utils/htmlFunctions";
import {IState as ITrainingState} from "../../reducers/training";
import {ITrainingMeta} from "../../models";
import {handleExportToCsvOrgUsers} from "./selectedOrgUserDetailsCsv";

const orgTableColumns = [
    {
        title: "User Name",
        key: "name",
        width: "50",
        extra: {
            isKey: true,
        },
    },
    {
        title: "Org login id",
        key: "loginId",
        width: "100",
    },
    {
        title: "Total Trainings",
        key: "trainings",
        value: (val) => {return val.length},
        width: "100",
    },
];

export default function SelectedOrganization(props: any) {
    let organizations: IOrganizationState = useSelector((state: IReduxState) => state.organizations);
    let training: ITrainingState = useSelector((state: IReduxState) => state.training);
    const storeDispatch = useDispatch();
    const orgId = props.location && props.location.state && props.location.state.orgId;
    const orgName = props.location && props.location.state && props.location.state.orgName;

    useEffect(() => {
        dispatch(storeDispatch, getSelectedOrganizations(orgId));
        dispatch(storeDispatch, getTrainingMeta());
    }, [storeDispatch, orgId]);

    function getAllTrainingsForSelectedOrganization() {
        let trainingId: string[] = [];
        const trainingDetails: ITrainingMeta[] = [];
        for (const element of organizations.organizations) {
            if (element.id === orgId) {
                trainingId = element.trainingIds
            }
        }
        for (const element of trainingId) {
            for (const ele of training.trainingList) {
                if (element === ele.id) {
                    trainingDetails.push(ele)
                }
            }
        }
        return trainingDetails;
    }
    return (
        <div>
            <div className="row">
                {organizations.loading && fullScreenLoader()}
                <div className="col-4">
                    <h4 style={{textAlign:"left", padding: 20}}><strong>{orgName}</strong></h4>
                </div>
                <div className="col-4">
                    { organizations.organizationResponse.status.trim() !== "" &&
                    <div className={organizations.organizationResponse.status === "SUCCESS" ? "text-success" : "text-danger"}>
                        <strong>{organizations.organizationResponse.msg}</strong>
                    </div>}
                </div>
                <div className="col-4">
                    <DeleteOrganization selectedOrgId={orgId}/>
                </div>
            </div>
            <div className="container-fluid d-flex justify-content-center login" style={{marginTop: -45}}>
                <div className="row">
                    <div className="col-6">
                        <Table
                            data={organizations.selectedOrganization}
                            columns={orgTableColumns}
                            multiColumnSearch
                            pagination
                            sortIndicator
                        />
                    </div>
                    <div className="col-6">
                        <TrainingTable selectedOrgTrainingList={getAllTrainingsForSelectedOrganization()}/>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <button style={{margin: 20}} className={"btn btn-primary"} onClick={() => handleExportToCsvOrgUsers(Object(organizations.selectedOrganization))} type="button">
                    Export User Details
                </button>
                <CreateNewUsers selectedOrgId={orgId}/>
                <AddTrainingToUsers selectedOrgId={orgId}/>
                <DeleteUsers selectedOrgId={orgId}/><br/>
            </div>
        </div>
    )
}