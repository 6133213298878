import {IActions} from "../models";

export enum Actions {
    REQUEST_LOGIN = "REQUEST_LOGIN",
}
export function handleLogin(base64String: string): IActions {
    return {
        actionName: Actions.REQUEST_LOGIN,
        type: "GET",
        url: "/getToken",
        authType: "Basic",
        token: base64String,
    }
}
