import {IUserLoginDetails} from "../../models";

export function downloadLoginDetails(loginDetails: IUserLoginDetails[]) {
    const csvData: any = [];
    const titles: string[] = ["LoginId", "Password"];

    const csvDetail = (item) => {
        const csvItem: any = [];
        csvItem.push(item.loginId);
        csvItem.push(item.password);
        return csvItem;
    };
    csvData.push(titles);
    loginDetails.forEach((item) => {
        csvData.push(csvDetail(item));
    });
    const b64 = window.btoa(csvData.join("\n"));
    const downloadData = `data:text/csv;base64, ${b64}`;
    const element = document.createElement("a");
    element.setAttribute("href", downloadData);
    element.download = "Login-Details.csv";

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
}