import {Actions} from "../actions/organization";
import {IOrganization, ISelectedOrganization, IUserLoginDetails} from "../models";
import {getOrganizationResponse} from "../components/organization/utils";

export interface IState {
    loading: boolean;
    organizations: IOrganization[];
    selectedOrganization: ISelectedOrganization;
    userloginDetails: IUserLoginDetails[];
    organizationResponse: {
        status: string;
        msg: string;
    },
}

const defaultState: IState = {
    loading: false,
    organizations: [],
    selectedOrganization: {
        id: "",
        name: "",
        loginId: "",
        trainings: [],
    },
    userloginDetails: [],
    organizationResponse: {
        status: "",
        msg: "",
    },
};

export default (state = defaultState, action: any) => {
    switch (action.type) {
        case Actions.REQUEST_GET_ALL_ORGANIZATIONS:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_GET_ALL_ORGANIZATIONS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                organizations: action.payload.orgs

            };
        case `${Actions.REQUEST_GET_ALL_ORGANIZATIONS}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_GET_SELECTED_ORGANIZATION:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_GET_SELECTED_ORGANIZATION}_SUCCESS`:
            return {
                ...state,
                loading: false,
                selectedOrganization: action.payload.users,
            };
        case `${Actions.REQUEST_GET_SELECTED_ORGANIZATION}_FAILURE`:
            return {
                ...state,
                loading: false,
                selectedOrganization: [],
            };
        case Actions.REQUEST_CREATE_NEW_ORGANIZATION:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_CREATE_NEW_ORGANIZATION}_SUCCESS`:
            return {
                ...state,
               loading: false,
            };
        case `${Actions.REQUEST_CREATE_NEW_ORGANIZATION}_FAILURE`:
            return {
                ...state,
                loading: false,
                organizationResponse: getOrganizationResponse("FAILURE", "Oops..! Something went wrong!"),
            };
        case Actions.REQUEST_CREATE_USERS_FOR_ORGANIZATION:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_CREATE_USERS_FOR_ORGANIZATION}_SUCCESS`:
            return {
                ...state,
                loading: false,
                userloginDetails: action.payload
            };
        case `${Actions.REQUEST_CREATE_USERS_FOR_ORGANIZATION}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_DELETE_ORGANIZATION:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_DELETE_ORGANIZATION}_SUCCESS`:
            return {
                ...state,
                loading: false,
                selectedOrganization: [],
            };
        case `${Actions.REQUEST_DELETE_ORGANIZATION}_FAILURE`:
            return {
                ...state,
                loading: false,
                organizationResponse: getOrganizationResponse("FAILURE", "Oops..! Something went wrong!"),
            };
        case Actions.REQUEST_DELETE_USERS:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_DELETE_USERS}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_DELETE_USERS}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_ASSIGN_TRAINING_TO_USERS:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_ASSIGN_TRAINING_TO_USERS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                organizationResponse: getOrganizationResponse("SUCCESS", "Training assigned to users successfully"),
            };
        case `${Actions.REQUEST_ASSIGN_TRAINING_TO_USERS}_FAILURE`:
            return {
                ...state,
                loading: false,
                organizationResponse: getOrganizationResponse("FAILURE", "Failed to assign training to user"),
            };
        case Actions.RESET_ORGANIZATION_RESPONSE: {
            return {
                ...state,
                organizationResponse: getOrganizationResponse("", ""),
            }
        }
        case Actions.REQUEST_ADD_TRAINING_TO_ORGANIZATION: {
            return {
                ...state,
                loading: true,
            }
        }
        case `${Actions.REQUEST_ADD_TRAINING_TO_ORGANIZATION}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_ADD_TRAINING_TO_ORGANIZATION}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.RESET_USERLOGIN_DETAILS: {
            return {
                ...state,
                userloginDetails: [],
            }
        }
        default:
            return state
    }
}